.App-header {
    /* background-color: #282c34; */
    margin-top: 10px;
    min-height: 50vh;
    /* align-items: center;
    justify-content: center; */
    margin: auto;
    /* color: white; */

}

.search-bar-container {
    display: grid;
    justify-content: center;
    margin-top: 10px;
    font-size: small;
    color: gray
}